<template>
  <h1 class="colorfull margin">Über das Projekt</h1>
  <div class="container justify-content-center align-content-center ">

    <div class="row">
      <div class="col-12 col-lg-4 margin" id="head"
           :style="{ backgroundImage: 'url(' + image + ')' }"></div>
      <div class="col-12 col-lg-8 margin">
        <p class="reading">Bei dieser Anwendung handelt es sich um die Bachelorarbeit von
          Patrick Linner an der Hochschule Augsburg, im Studiengang Interaktive Medien.
          <br>Betreut wurde das Projekt von Prof. Andreas Muxel und Elias Naphausen.<br>
          <br>

          Intention der Arbeit ist es, auf eine eindrückliche
          emotionale Weise, dem Thema „Big Data“ zu begegnen und dabei den eigenen Umgang
          zu hinterfragen. Da die Auswirkungen auf uns und unseren Alltag eine entscheidende
          Rolle spielen, ist es wichtig der Thematik reflektiert entgegen zu treten.
        </p>
        <p class="reading">Bei Fragen, Anregungen oder Jobangeboten
          freue ich mich von ihnen zu hören:
          <a href="mailto:media@patrick-linner.de">media@patrick-linner.de</a></p>
        <p class="reading">Weitere spannende Projekte sind auf meiner Portfolioseite zu finden: <a href="https://patrick-linner.de/">www.patrick-linner.de</a></p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'infos',
  data() {
    return {
      // eslint-disable-next-line global-require
      image: require('@/assets/media/head_transp.png'),
    };
  },
};
</script>

<style scoped>

#head {
  min-height: 200px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.margin {
  margin-top: 5vh;
}

a:hover {
  color: #ffffff;
}

a {
  color: rgb(213, 59, 185);
}
</style>
